<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
		 aria-labelledby="myLargeModalLabel" (onShow)="onShowModal($event)" (onShown)="onShownModal($event)"
		 (onHidden)="onHiddenModal($event)"
		 aria-hidden="true">
	<div class="modal-dialog {{large ? 'modal-lg' : ''}}  {{extraLarge ? 'modal-xl' : ''}} {{fullWidth ? 'modal-full-width' : ''}}">
		<div class="modal-content">
			<div class="modal-header" *ngIf="headerVisible">
				<h4 class="modal-title  w-100 text-center">{{title}}</h4>
				<button *ngIf="showCloseButton" type="button" class="close pull-right" (click)="lgModal.hide()"
								aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<ng-content></ng-content>
			</div>

			<div class="modal-footer" *ngIf="footerVisible">
				<button [style.float]="'left'" type="button" class="btn btn-danger btn-fill m-1" data-dismiss="modal"
								id="button_extra" *ngIf="!extraButtonHidden"
								(click)="onExtraClicked()">{{extraText}}
				</button>
				<button [style.float]="'right'" type="button" class="btn btn-primary btn-fill m-1" [disabled]="!isValid()"
								id="button_save" *ngIf="!confirmButtonHidden"
								(click)="onConfirmClicked()">{{confirmButtonText}}
				</button>
				<button [style.float]="'right' " type="button" class="btn btn-default btn-fill m-1" data-dismiss="modal"
								id="button_cancel" *ngIf="!cancelButtonHidden"
								(click)="onCancelClicked()">Cancel
				</button>
			</div>
		</div>
	</div>
</div>

