<div class="fixed-top" style="background: #1e1e2f; height: 45px">

  <div class="row m-0 p-0">

    <div class="col-auto p-0">
      <a href="/">
        <img src="assets/img/logo-noc.png" style="height: 45px" alt=""/>
      </a>
    </div>

    <div class="col p-2">
      <h6 class="m-0 text-white">Internal Orders<br/><span
        class="small-text">Powered by RMS™</span></h6>
    </div>

    <div class="col-auto text-right d-none d-md-block">
      <img [src]="logo" style="padding: 5px; height: 45px" alt=""/>
    </div>

    <div class="col-auto p-1 text-right" *ngIf="dataService.getUserName()">

      <div class="right dropdown mr-2 mt-1" dropdown>
        <button class="btn btn-link d "
                style="font-size: 12px; color: whitesmoke"
                data-toggle="dropdown"
                dropdownToggle
                type="button">
          Welcome, {{dataService.getUserName()}}
        </button>
        <div class=" dropdown-menu dropdown-menu-right" *dropdownMenu>
          <a class=" dropdown-item" (click)="deviceProfile()">Device Profile</a>
          <a class="dropdown-item" (click)="resetAuthCode()">Reset auth code</a>
          <a class=" dropdown-item" (click)="logout()">Logout</a>
        </div>
      </div>
    </div>

  </div>


</div>
