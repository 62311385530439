import { OrderSnapshot, OrdersSnapshotValue } from '../model/noc';
import { string } from "mathjs";

export class Helper {

  static copyObject(value: object): any {
    return JSON.parse(JSON.stringify(value));
  }

  static stateNumber(order: OrdersSnapshotValue): number {
    return Helper.orderStateNumber(order.value);
  }

  static orderStateNumber(order: OrderSnapshot): number {
    if (order.state == 'READY') {
      return 5;
    }
    if (order.state == 'PREPARING') {
      return 4;
    }
    if (order.state == 'PICKING_UP') {
      return 3;
    }
    if (order.state == 'ACCEPTED') {
      return 2;
    }
    if (order.state == 'COMPLETED') {
      return 1;
    }

    return 0;
  }

  static gradientColor(ctx: CanvasRenderingContext2D, color: string): any {
    const gradientStrokeBlue = ctx.createLinearGradient(0, 230, 0, 50);

    if (color.length === 7) {
      color += '22';
    }
    gradientStrokeBlue.addColorStop(1, color);
    // gradientStrokeBlue.addColorStop(0.4, 'rgba(255,255,255,0.0)');
    // gradientStrokeBlue.addColorStop(0, 'rgba(29,140,248,0)');
    return gradientStrokeBlue;
  }


  static getColorFromOrderId(orderId: number): string {
    if (!orderId) return 'white'

    const lightColors = [
      '#FFEBEE', // Light Pink
      '#FFCCBC', // Light Orange
      '#D1C4E9', // Light Purple
      '#C5E1A5', // Light Green
      '#BBDEFB', // Light Blue
      '#B2DFDB', // Light Teal
      '#FFE0B2', // Light Amber
      '#E1BEE7', // Light Lavender
      '#F8BBD0', // Light Coral
      '#FFF9C4', // Light Yellow
      '#F0F4C3', // Light Lime
      '#E0F7FA', // Light Cyan
      '#FFCDD2', // Light Red
      '#C8E6C9', // Light Mint
      '#D7CCC8', // Light Beige
      '#B3E5FC', // Light Sky Blue
      '#F0B8E8', // Light Fuchsia
      '#B2EBF2', // Light Aquamarine
      '#E6EE9C', // Light Olive
      '#FFAB40', // Light Amber Orange
      '#C6FF00', // Light Chartreuse
    ];

    // Use modulo to select a color based on the order ID
    const index = orderId % lightColors.length; // Wrap around the length of the array
    return lightColors[index];
  }

}
