import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment-timezone';

@Pipe({ name: 'dictionaryValues' })
export class KeysValuePipe implements PipeTransform {
    transform(value): any {
        const keys = [];
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                keys.push({ key, value: value[key] });
            }
        }
        return keys;
    }
}

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {

    transform(value: number): string {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = Math.floor(value % 60);

        return (hours < 10 ? '0' : '') + hours + (minutes < 10 ? ':0' : ':') + minutes + (seconds < 10 ? ':0' : ':') + seconds;
    }

}

@Pipe({ name: 'minute' })
export class MinutePipe implements PipeTransform {

    transform(value: number): string {

        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = Math.floor(value % 60);

        return (hours < 10 ? '0' : '') + hours + (minutes < 10 ? ':0' : ':') + minutes + (seconds < 10 ? ':0' : ':') + seconds;
    }

}

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {

    transform(value: number): string {
        value *= 60;

        const minutes = Math.floor((value % 3600) / 60);
        const seconds = Math.floor(value % 60);

        return (minutes < 10 ? '0' : '') + minutes + (seconds < 10 ? ':0' : ':') + seconds;
    }

}


@Pipe({ name: 'los_angeles' })
export class MomentDatePipe extends DatePipe implements PipeTransform {
    // @ts-ignore
    transform(
        value: string | Date | number,
        format: string = 'mediumDate',
        timezone: string = 'America/Los_Angeles'
    ): string {
        const timezoneOffset = moment(value).tz(timezone).format('Z');
        return super.transform(value, format, timezoneOffset);
    }
}


@Pipe({ name: 'kiosk_timezone' })
export class KioskTimezoneDatePipe extends DatePipe implements PipeTransform {
    // @ts-ignore
    transform(
        value: string | Date | number,
        format: string,
        timezone?: string
    ): string {
        if (value && timezone) {
            const timezoneOffset = moment(value).tz(timezone)?.format('Z');
            const transform = super.transform(value, format, timezoneOffset);
            const arr = transform.split(' ');
            const abbr = moment(value).tz(timezone).zoneAbbr();

            arr.pop();
            arr.push(abbr);
            return arr.join(' ');
        }
        return '';
    }
}
