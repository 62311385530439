import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import $ from 'jquery';

@Component({
    selector: 'app-modal-component',
    templateUrl: 'modal.component.html',
})

export class ModalComponent {

    title: string;
    @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;

    @Input() confirmButtonText = 'Save';
    @Input() validation;
    @Input() large = true;
    @Input() extraLarge = false;
    @Input() fullWidth = false;
    @Input() autoHide = true;
    @Input() confirmButtonHidden = false;
    @Input() cancelButtonHidden = false;
    @Input() extraText = '';
    @Input() extraButtonHidden = true;
    @Input() showCloseButton = false

    @Output() onConfirm: EventEmitter<any> = new EventEmitter();
    @Output() onHidden: EventEmitter<any> = new EventEmitter();
    @Output() onShown: EventEmitter<any> = new EventEmitter();
    @Output() onExtra: EventEmitter<any> = new EventEmitter();

    footerVisible = true;
    headerVisible = true;

    constructor() {
    }

    isValid() {
        if (typeof this.validation === 'function') {
            return this.validation();
        } else {
            return true;
        }
    }

    onShowModal(ev) {

        $('.base-modal').appendTo('body');

        const zIndex = 1050 + (10 * $('.modal:visible').length);
        $('.modal:hidden').css('z-index', zIndex);

        setTimeout(() => {
            $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
        }, 0);
    }

    onShownModal(ev) {
        this.onShown.emit();
    }

    onHiddenModal(ev) {
        $('.modal:visible').length && $(document.body).addClass('modal-open');
        this.onHidden.emit();
    }

    onCancelClicked() {
        this.modal.hide();

    }

    onConfirmClicked() {
        this.onConfirm.emit();
        if (this.autoHide) {
            this.modal.hide();
        }
    }

    show(title: string) {
        this.title = title;
        this.modal.show();
    }

    setFooterVisibility(visible: boolean) {
        this.footerVisible = visible;
    }

    setHeaderVisibility(visible: boolean) {
        this.headerVisible = visible;
    }

    onExtraClicked() {
        this.onExtra.emit();
    }
}
