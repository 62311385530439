import { Component } from '@angular/core';
import { AppComponent } from '../app.component';
import { OrderService } from '../services/order.service';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { StoreManagerService } from '../services/store-manager.service';

@Component({
    template: '<div></div>'
})
export abstract class BaseOrderComponent {

    reasonHtml = `<div class=" row  m-0"  style="margin-top: 1.3rem !important ">
            <div class=" col " style="padding-top: 10px; font-size: 14px">
              Select Reason: 
              </div>
              <div class="col-8">
				 <select id="select_reason" class="form-control form-control-low selectx" style="color: black" >
				     <option></option>
                     <option>Spill</option>
                     <option>Ghost Jar</option>
                     <option>Empty Cup or Under Pour</option>
                     <option>Taking too long >10 mins</option>
                     <option>Robot Stuck</option>
                     <option>Kiosk is OOS</option>
                     <option>Test Drink</option>
                     <option>Other</option>
                 </select>
			</div>
		</div>`;

    pinCodeHtml = `<div class=" row  m-0"  style="margin-top: 1.3rem !important ">
            <div class=" col " style="padding-top: 10px; font-size: 14px">
              Enter PIN code: 
              </div>
              <div class="col-8">
				 <input id="select_pin" class="form-control form-control-low " style="color: black" type="text" >
			</div>
		</div>`;

    protected constructor(
        protected app: AppComponent,
        protected orderService: OrderService,
        protected storeManagerService: StoreManagerService,
        protected sweetAlert2LoaderService: SweetAlert2LoaderService) {
    }

    private showConfirmation(text: string, onConfirm: () => any) {
        this.sweetAlert2LoaderService.swal.then((swal) => {
            swal.fire({
                title: 'Confirmation',
                text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No, Dismiss'
            }).then((result) => {
                if (result.value) {
                    onConfirm();
                }
            });
        });
    }

    showPickupConfirmation(kioskId: number, orderId: number, recipeName: string) {
        this.showConfirmation('Are you sure you want to pickup ' + recipeName + '?', () => {
            this.orderService.pickup(orderId, kioskId).subscribe(response => {
                if (response.success) {
                    this.app.showNotification('Picked up');
                } else {
                    this.app.showError('Pickup failed: ' + response.errorDescription);
                }
            }, error => {
                this.app.showError('Pickup failed');
            });
        });
    }

    showRefundConfirmation(kioskId: number, orderId: number, recipeName: string, pinCodeForRefund: string) {
        let text = 'Are you sure you want to REFUND ' + recipeName + '?';
        text += this.reasonHtml;
        if (pinCodeForRefund != null && pinCodeForRefund != '') text += this.pinCodeHtml;

        this.sweetAlert2LoaderService.swal.then((swal) => {
            swal.fire({
                title: 'Confirmation',
                html: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No, Dismiss',
                preConfirm: () => {
                    if (pinCodeForRefund != null && pinCodeForRefund != '') {
                        const pin = document.getElementById('select_pin')['value'];
                        return pin.length > 0
                    }
                    return true;
                }
            }).then((result) => {
                if (result.value) {

                    const reason = document.getElementById('select_reason')['value'];

                    if (pinCodeForRefund != null && pinCodeForRefund != '') {
                        const pin = document.getElementById('select_pin')['value'];
                        if (pin != pinCodeForRefund) {
                            this.app.showError('Invalid PIN code');
                            return
                        }
                    }

                    this.storeManagerService.refundOrder(kioskId, orderId, reason, pinCodeForRefund).subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('Refunded');
                        } else {
                            this.app.showError('Refund failed: ' + response.errorDescription);
                        }
                    }, error => {
                        this.app.showError('Refund failed');
                    });

                }
            });
        });

    }

    showReorderConfirmation(kioskId: number, orderId: number, recipeName: string, pinCodeForRefund: string) {
        let text = 'Are you sure you want to REORDER ' + recipeName + '?';
        text += this.reasonHtml;
        if (pinCodeForRefund != null && pinCodeForRefund != '') text += this.pinCodeHtml;

        this.sweetAlert2LoaderService.swal.then((swal) => {
            swal.fire({
                title: 'Confirmation',
                html: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No, Dismiss',
                preConfirm: () => {
                    if (pinCodeForRefund != null && pinCodeForRefund != '') {
                        const pin = document.getElementById('select_pin')['value'];
                        return pin.length > 0
                    }
                    return true;
                }
            }).then((result) => {
                if (result.value) {

                    const reason = document.getElementById('select_reason')['value'];

                    if (pinCodeForRefund != null && pinCodeForRefund != '') {
                        const pin = document.getElementById('select_pin')['value'];
                        if (pin != pinCodeForRefund) {
                            this.app.showError('Invalid PIN code');
                            return
                        }
                    }

                    this.storeManagerService.reorderOrder(kioskId, orderId, reason).subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('Reordered');
                        } else {
                            this.app.showError('Reorder failed: ' + response.errorDescription);
                        }
                    }, error => {
                        this.app.showError('Reorder failed');
                    });

                }
            });
        });
    }

    showCancelConfirmation(kioskId: number, orderId: number, recipeName: string, pinCodeForRefund: string) {

        let text = 'Are you sure you want to cancel the order?';

        text += `<div class=" row  m-0" style="margin-top: 2.3rem !important">
             <div class=" col-4 ">
                <div class="custom-control custom-radio mb-3">
                   <input name="cancel_mode" class="custom-control-input" id="cancel_only" checked="" type="radio">
                   <label class="custom-control-label" for="cancel_only" style="font-size: 14px">Cancel<br/>Only</label>
                </div>
            </div>
			<div class="col-4"> 
                <div class="custom-control custom-radio mb-3">
                  <input name="cancel_mode" class="custom-control-input" id="cancel_and_refund" type="radio">
                  <label class="custom-control-label" for="cancel_and_refund" style="font-size: 14px">Cancel and Refund</label>
                </div>
            </div>
            <div class="col-4"> 
                <div class="custom-control custom-radio mb-3">
                  <input name="cancel_mode" class="custom-control-input" id="cancel_and_reorder" type="radio">
                  <label class="custom-control-label" for="cancel_and_reorder" style="font-size: 14px">Cancel and Re-order</label>
                </div>
            </div>
		</div>`;

        text += this.reasonHtml;
        if (pinCodeForRefund != null && pinCodeForRefund != '') text += this.pinCodeHtml;

        text += `<div class=" row m-0">
            <div class=" col ">
				<div class=" form-check form-check-inline" style="margin-top: 2.3rem !important">
					<label class=" form-check-label">
						<input class=" form-check-input" type="checkbox" id="disabling_reason"  checked >
						<span class=" form-check-sign"> </span> Send SMS
					</label>
				</div>
			</div>
		</div>`;

        this.sweetAlert2LoaderService.swal.then((swal) => {
            swal.fire({
                title: 'Confirmation',
                html: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                preConfirm: () => {
                    if (pinCodeForRefund != null && pinCodeForRefund != '') {
                        const pin = document.getElementById('select_pin')['value'];
                        return pin.length > 0
                    }
                    return true;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    const send_sms = document.getElementById('disabling_reason')['checked'];
                    const cancel_only = document.getElementById('cancel_only')['checked'];
                    const cancel_and_refund = document.getElementById('cancel_and_refund')['checked'];
                    const cancel_and_reorder = document.getElementById('cancel_and_reorder')['checked'];
                    const reason = document.getElementById('select_reason')['value'];

                    if (pinCodeForRefund != null && pinCodeForRefund != '') {
                        const pin = document.getElementById('select_pin')['value'];
                        if (pin != pinCodeForRefund) {
                            this.app.showError('Invalid PIN code');
                            return
                        }
                    }

                    this.storeManagerService.cancelOrder(kioskId, orderId, reason, send_sms).subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('Canceled');
                        } else {
                            this.app.showError('Cancel failed: ' + response.errorDescription);
                        }
                    }, error => {
                        this.app.showError('Cancel failed');
                    });

                    if (cancel_and_refund) {
                        this.storeManagerService.refundOrder(kioskId, orderId, reason, pinCodeForRefund).subscribe(response => {
                            if (response.success) {
                                this.app.showNotification('Refunded');
                            } else {
                                this.app.showError('Refund failed: ' + response.errorDescription);
                            }
                        }, error => {
                            this.app.showError('Refund failed');
                        });
                    }

                    if (cancel_and_reorder) {
                        this.storeManagerService.reorderOrder(kioskId, orderId, reason).subscribe(response => {
                            if (response.success) {
                                this.app.showNotification('Reordered');
                            } else {
                                this.app.showError('Reorder failed: ' + response.errorDescription);
                            }
                        }, error => {
                            this.app.showError('Reorder failed');
                        });
                    }
                }
            });
        });
    }

}
