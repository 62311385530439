import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { StoreManagerService } from '../../services/store-manager.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  logo: string;

  constructor(public dataService: DataService,
              private router: Router,
              private storeManagerService: StoreManagerService,
              protected sweetAlert2LoaderService: SweetAlert2LoaderService) {
  }

  ngOnInit() {
  }

  redirect(token: string) {
    this.dataService.logout()
    if (token) {
      this.dataService.clear()
      this.router.navigate(['/auth']).then()
    } else {
      this.router.navigate(['/user']).then()
    }
  }

  logout() {
    const token = DataService.getToken();

    this.storeManagerService.logout(this.dataService.getSelectedKioskId(), this.dataService.getUserName()).subscribe(response => {
      this.dataService.logout()
      this.redirect(token)
    }, error => {
      this.redirect(token)
    })
  }

  deviceProfile() {
    this.sweetAlert2LoaderService.swal.then((swal) => {
      swal.fire({
        title: 'Device ID',
        text: this.dataService.getProvisioning().tablet.tabletId,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Close',
      })
    })
  }

  resetAuthCode() {
    this.sweetAlert2LoaderService.swal.then((swal) => {
      swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to reset auth code?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
          this.dataService.clear()
          this.router.navigate(['/auth']).then()
        }
      })
    })
  }
}
