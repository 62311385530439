import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { Observable } from 'rxjs';
import { PurchaseRequestBean, PurchaseResponseBean, ResponseBean } from '../model/model';

@Injectable()
export class OrderService extends BaseRequestService {


    pickup(orderId: number, kioskId: number): Observable<ResponseBean> {
        return this.getWithKioskToken(kioskId, 'orders/' + orderId + '/pick_up', 1);
    }

    purchaseCart(cart: PurchaseRequestBean): Observable<PurchaseResponseBean> {
        return this.postWithKioskToken(cart.kioskId, 1, 'cart/purchase', cart);
    }
}
