import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { DeviceSnapshot, IngredientSnapshot, KioskNOC } from '../../model/noc';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { AppComponent } from '../../app.component';
import { DiagnosticsStepSequenceBean, IngredientBean } from '../../model/model';
import { StoreManagerService } from '../../services/store-manager.service';
import { DataService } from 'src/app/services/data.service';
import { AngularFireDatabaseNocService } from '../../services/fire.service';
import moment from 'moment-timezone';
import { Helper } from "../../common/helper";

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
})
export class DeviceComponent implements OnInit {

  @Input() device: DeviceSnapshot;
  @Input('noc') kioskNOC: KioskNOC;
  @Input() replaceName: string;
  @Input() kioskId: number;
  @Input() stepsList: DiagnosticsStepSequenceBean[];
  @Input() spill: boolean;
  @Input() isDispenser: boolean;
  @Input() isJumbotron: boolean;
  @Input() textColor = '#525f7f';
  @Input() subCardColor = 'grayer';
  @Input() ingredients: IngredientBean[];

  @Output() onProvisionIngredients = new EventEmitter<DeviceSnapshot>();
  @Output() onDisableIngredients = new EventEmitter<DeviceSnapshot>();
  @Output() onDeactivateDevice = new EventEmitter<DeviceSnapshot>();
  @Output() onAnalytics = new EventEmitter<DeviceSnapshot>();
  @Output() onViewImage = new EventEmitter<DeviceSnapshot>();

  DataService = DataService;

  @Input() viewImageCommand: Boolean = false;
  @Input() analyticsCommand: Boolean = false;
  @Input() restartCommand: Boolean = true;
  @Input() refillCommand: Boolean = true;
  @Input() unlockCommand: Boolean = false;

  @Input() setLevelsCommand: Boolean = true;
  @Input() disableIngredientsCommand: Boolean = false;

  @Input() deliverCommand: Boolean = false;
  @Input() refillCommandText = 'Refill';
  @Input() deactivateDeviceCommand: Boolean = false;

  getColor(value: number) {
    if (value > 25) return 'rgba(53,152,38,0.23)'
    if (value > 10) return 'rgba(255,151,66,0.23)'
    return 'rgba(255,0,0,0.23)'
  }

  constructor(
    private storeManagerService: StoreManagerService,
    private sweetAlert2LoaderService: SweetAlert2LoaderService,
    protected nocDatabaseService: AngularFireDatabaseNocService,
    @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
  }

  ngOnInit() {
  }

  getIngredientPercent(ingredient: IngredientSnapshot) {
    return Math.round(ingredient.current_amount <= 0 ? 0 : ingredient.current_amount / ingredient.max_amount * 100);
  }

  getState() {
    return this.device.invalid ? 'INVALID' : this.getStateString();
  }

  private getStateString() {
    if (this.device.disabled) {
      return 'OFF';
    }
    switch (this.device.state) {
      case 'OUT_OF_SERVICE':
        return 'OOS';
      case 'ACTIVE':
        return 'ON';
      case 'FAILED':
        return 'FAIL';
      case 'PROVISIONED':
        return 'PROV';
    }
  }

  analytics(device: DeviceSnapshot) {
    this.onAnalytics?.emit(device);
  }

  isOffline() {
    const dt = moment().valueOf() - (this.device.heartbeat_timestamp ?? 0);
    return dt > 20_000;
  }

  getCustomerName() {
    const order = Object.values(this.kioskNOC.orders ?? {}).find(it => it.value.jar_name == this.device.name)
    return order && '🙋🏻‍♂️' + order?.value?.customer_name;
  }

  getJarPlace() {
    const order = Object.values(this.kioskNOC.orders ?? {}).find(it => it.value.jar_place == this.device.name)
    return order && '🙋🏻‍♂️' + order?.value?.customer_name
  }

  protected readonly Helper = Helper;

  getOrderId() {
    const order = Object.values(this.kioskNOC.orders ?? {}).find(it =>
      it.value.jar_place == this.device.name || it.value.jar_name == this.device.name)
    return order?.value?.id;
  }

  getIngredientName(ing: IngredientSnapshot) {
    return ` ${ing.name}`;
  }

  getIngredientImage(ing: IngredientSnapshot) {
    return this.ingredients?.find(it => it.id == ing.id)?.imageUrl
  }
}
