export enum FieldType {
    TEXT, NUMBER, TEXT_AREA, IMAGE,
    FILE, VIDEO, SELECT, DATE, DATE_TIME, TIME,
    DURATION, TIMEZONE, TEL, CHECKBOX,
    COLOR, MULTISELECT, WYSIWYG
}

export class FieldTypeHelper {
    static toString(fieldType: FieldType): string {
        switch (fieldType) {
            case FieldType.TEXT:
            case FieldType.DATE_TIME:
            case FieldType.DURATION:
            case FieldType.TIME:
                return 'text';
            case FieldType.NUMBER:
                return 'number';
            case FieldType.TEXT_AREA:
            case FieldType.WYSIWYG:
                return 'textarea';
            case FieldType.IMAGE:
            case FieldType.VIDEO:
            case FieldType.FILE:
                return 'file';
            case FieldType.SELECT:
                return 'select';
            case FieldType.DATE:
                return 'date';
            case FieldType.TIMEZONE:
                return 'timezone';
            case FieldType.TEL:
                return 'tel';
        }
        return '';
    }
}

export class Field {
    public title: string;
    public required: boolean;
    public maxSelectedItems = 100;
    public isVideo: boolean;
    public disabled: boolean;
    public field: string;
    public subfield: string;
    public fieldType: FieldType;
    public options: string[] = [];
    public ifFunction: (bean: any) => any;
    public changeObject: object;
    public changeFunction: (bean: any) => any;
    public clickObject: object;
    public clickFunction: (bean: any) => any;
    public disableIfFunction: (bean: any) => any;
    public multiselectOptions: any[];

    constructor(field: string, title: string, fieldType: FieldType = FieldType.TEXT) {
        this.field = (field ? field.split('.')[0] : null);
        this.subfield = (field ? field.split('.')[1] : null);
        this.title = title;
        this.fieldType = fieldType;
    }

    setOptions(options: string[]): Field {
        this.options = options;
        return this;
    }

    setMultiselectOptions(options: any[]): Field {
        this.multiselectOptions = options;
        return this;
    }

    disable(ifFunction: (bean: any) => any = null): Field {

        this.disableIfFunction = ifFunction;
        this.disabled = true;
        return this;
    }

    require(): Field {
        this.required = true;
        return this;
    }

    maxSelected(cnt: number): Field {
        this.maxSelectedItems = cnt;
        return this;
    }

    video(): Field {
        this.isVideo = true;
        return this;
    }


    if(ifFunction: (bean: any) => any) {
        this.ifFunction = ifFunction;
        return this;
    }

    change(dataObject: object, dataFunction: (bean: any) => any) {
        this.changeObject = dataObject;
        this.changeFunction = dataFunction;
        return this;
    }

    click(dataObject: object, dataFunction: (bean: any) => any) {
        this.clickObject = dataObject;
        this.clickFunction = dataFunction;
        return this;
    }

}
