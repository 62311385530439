import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment-timezone';
import { Field, FieldType, FieldTypeHelper } from '../../common/field';
import { VideoBean } from '../../model/model';

@Component({
    selector: 'app-fields-list-component',
    templateUrl: 'fields-list.component.html'
})
export class FieldsListComponent implements AfterViewInit {

    @Input() name = 'control';
    @Input() colWidth = 6;
    @Input() fields: Field[];
    @Input() kioskId: number;
    @Input() textAreaRows = 4;

    dates: Date[] = [];
    @Output() onVideoUploaded = new EventEmitter<VideoBean>();

    constructor(private elRef: ElementRef) {
    }

    originValue: any;

    @Input()
    set value(value: any) {
        this.originValue = value;

        const tz = this.fields.find(it => it.fieldType == FieldType.TIMEZONE)?.field;
        if (tz) {
            this.timezone = this.originValue[tz];
        }
        const time = this.fields.find(it => it.fieldType == FieldType.TIME)?.field;
        if (time) {
            let m = moment.unix(this.originValue[time]);
            this.time = m.toDate();
            this.time.setHours((this.originValue[time] / 60 / 60));
        }

        this.dates = [];
    }

    get value() {
        return this.originValue;
    }

    selectedField: Field;
    timezone: any;
    time: Date;

    public valid() {
        if (!this.fields) {
            return true;
        }
        for (const f of this.fields) {
            if (!this.available(f)) {
                continue;
            }

            if (f.required && !this.originValue[f.field]) {
                return false;
            }
        }
        return true;
    }

    isObject(obj) {
        return obj instanceof Object;
    }

    getType(fieldType: FieldType) {
        return FieldTypeHelper.toString(fieldType);
    }

    isInput(fieldType: FieldType) {
        switch (fieldType) {
            case FieldType.TEXT:
            case FieldType.NUMBER:
            case FieldType.TEL:
                return true;
            default:
                return false;
        }
    }

    isDate(fieldType: FieldType) {
        return fieldType === FieldType.DATE;
    }

    isDateTime(fieldType: FieldType) {
        return fieldType === FieldType.DATE_TIME;
    }

    isTime(fieldType: FieldType) {
        return fieldType === FieldType.TIME;
    }

    isTimezone(fieldType: FieldType) {
        return fieldType === FieldType.TIMEZONE;
    }

    isDuration(fieldType: FieldType) {
        return fieldType === FieldType.DURATION;
    }

    isCheckbox(fieldType: FieldType) {
        return fieldType === FieldType.CHECKBOX;
    }

    isSelect(fieldType: FieldType) {
        return fieldType === FieldType.SELECT;
    }

    isMultiselect(fieldType: FieldType) {
        return fieldType === FieldType.MULTISELECT;
    }

    isWysiwyg(fieldType: FieldType) {
        return fieldType === FieldType.WYSIWYG;
    }

    isTextArea(fieldType: FieldType) {
        return fieldType === FieldType.TEXT_AREA;
    }

    isColor(fieldType: FieldType) {
        return fieldType === FieldType.COLOR;
    }

    isImage(fieldType: FieldType) {
        return fieldType === FieldType.IMAGE;
    }

    isVideo(fieldType: FieldType) {
        return fieldType === FieldType.VIDEO;
    }

    isFile(fieldType: FieldType) {
        return fieldType === FieldType.FILE;
    }

    public getValue(fieldName: string) {
        const selector = '#control_' + fieldName.replace('.', '');
        const div = this.elRef.nativeElement.querySelector(selector);
        if (div) {
            return div.value;
        }
        return null;
    }

    ngAfterViewInit(): void {
    }

    date(field: string, subfield: string = '') {

        if (!this.dates[field + subfield]) {
            if (this.originValue[field]) {

                const date = moment(subfield !== '' ? this.originValue[field][subfield] : this.originValue[field]).toDate();
                const losAngelesOffset = moment.tz(date.getTime(), 'America/Los_Angeles').utcOffset();
                const utcOffset = moment().utcOffset();
                const offset = losAngelesOffset - utcOffset;
                const newDate = moment(date.getTime()).add(offset, 'minutes');

                this.dates[field + subfield] = newDate.toDate();
            }
        }
        return this.dates[field + subfield];
    }

    saveBsDate(date: Date, field, subfield: string = '') {

        if (date) {
            const losAngelesOffset = moment.tz(date.getTime(), 'America/Los_Angeles').utcOffset();
            const utcOffset = moment().utcOffset();
            const offset = losAngelesOffset - utcOffset;
            const newDate = moment(date.getTime()).subtract(offset, 'minutes');

            if (subfield !== '') {
                this.originValue[field][subfield] = newDate.valueOf();
            } else {
                this.originValue[field] = newDate.valueOf();
            }
        }
    }

    selectImage(field: Field) {
    }

    onImageSelected(selectedImage: string) {
        if (this.selectedField.subfield) {
            this.originValue[this.selectedField.field][this.selectedField.subfield] = selectedImage;
        } else {
            this.originValue[this.selectedField.field] = selectedImage;
        }
    }

    available(field: Field) {
        return !field.ifFunction || field.ifFunction.call(null, this.originValue);
    }

    canDisable(field: Field) {
        return field.disabled && (!field.disableIfFunction || field.disableIfFunction.call(null, this.originValue));
    }

    onSelectChange(field: Field) {
        if (field.changeFunction) {
            field.changeFunction.call(field.changeObject);
        }
    }

    zoneChange(date: any, field, subfield: string = '') {
        if (date) {
            if (subfield !== '') {
                this.originValue[field][subfield] = date.nameValue;
            } else {
                this.originValue[field] = date.nameValue;
            }

            this.timezone = date.nameValue;
        }
    }

    uploadVideo() {
    }

    uploadFile() {
    }

    clearVideo(field: string) {
        this.originValue[field] = '';
    }

    timeChange(field, subfield: string = '') {
        const m = moment(this.time.getTime());
        let seconds = m.hours() * 3600 + m.minutes() * 60 + m.seconds();

        if (subfield !== '') {
            this.originValue[field][subfield] = seconds;
        } else {
            this.originValue[field] = seconds;
        }
    }

}

