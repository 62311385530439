export const environment = {
    production: true,
    IS_DEV: false,
    SERVER_URL: 'https://api.6dbytes.com/',

    FIREBASE_CONFIG: {
        apiKey: 'AIzaSyAVbVFVOKzK8C4BL8V7rqTBIAqnZepZ8-Y',
        authDomain: 'pacific-apex-122622.firebaseapp.com',
        databaseURL: 'https://pacific-apex-122622.firebaseio.com',
        projectId: 'pacific-apex-122622',
        storageBucket: 'pacific-apex-122622.appspot.com',
        messagingSenderId: '515987041167'
    },

    FILE_STORAGE_UPLOAD_URL: 'https://www.googleapis.com/upload/storage/v1/b/6dbytes_images/o?uploadType=media&name=',
    FILE_STORAGE_URL: 'https://storage.googleapis.com/6dbytes_images/',
};
