import { Injectable, NgZone } from '@angular/core';
import { AppSettings } from '../app.settings';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ɵAngularFireSchedulers } from '@angular/fire';

@Injectable()
export class AngularFireDatabaseNocService extends AngularFireDatabase {
}

export function AngularFireDatabaseNocFactory(platformId: object, zone: NgZone) {
    const config = AppSettings.getFirebaseConfig();
    return new AngularFireDatabase(config, 'noc', config.databaseURL, platformId, zone, new ɵAngularFireSchedulers(zone),
        null, null, null, null, null, null, null,
        null, null);
}

@Injectable()
export class AngularFireDatabaseVisionService extends AngularFireDatabase {
}
