<div class="card m-0 mb-1" [style.color]="textColor" [style.background-color]="Helper.getColorFromOrderId(order?.id)">
  <div class="card-content pt-2 pb-2" *ngIf="order">

    <div class="row m-0 p-0">
      <div class="col-6  ">
    				<span class="large-text">
    					{{ order.customer_name }}
    				</span>
      </div>

      <div class="col-6  d-flex justify-content-end ">
    				<span class="large-text">
    					{{ order.recipe }}
    				</span>
      </div>
    </div>

    <div class="row m-0 p-0">
      <div class="col-12 d-flex justify-content-end">
        <ng-container
          *ngIf="order.state==='READY' || (order.state==='CANCELLED' && order.expired && order.discard_date==null)">
          <button class="btn btn-small btn-sm" (click)="pickup(order)">Pick Up</button>
        </ng-container>
      </div>
    </div>

    <!--    <div class="row m-0 p-0">-->
    <!--      <div class="col-4 p-0 pl-1 m-0">-->
    <!--				<span class="large-text">-->
    <!--					{{ order.customer_name }}-->
    <!--				</span>-->

    <!--        <br/>-->
    <!--        <span class="small-text">-->
    <!--					{{ (order.price + (order.tax_amount ? order.tax_amount : 0)) | currency: 'USD': '$': '1.1-2' }},-->
    <!--          {{ formatOrderDetails(order) }}-->
    <!--				</span>-->
    <!--      </div>-->
    <!--      <div class="col-4 p-0">-->
    <!--        <h6 [innerHTML]="formatState()"></h6>-->
    <!--      </div>-->
    <!--      <div class="col-4 p-0">-->
    <!--				<span class="large-text"-->
    <!--              style="white-space: nowrap; display: block;   overflow: hidden; text-overflow: ellipsis;">-->
    <!--					{{ order.recipe }}-->
    <!--				</span>-->
    <!--        <br/>-->
    <!--        <span class="small-text"> {{ order.id }} </span>&nbsp;-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="row m-0">-->
    <!--      <div class="col-4 p-0 pl-1 m-0">-->
    <!--        <span class="small-text">	Ready: {{ order.ready_pct }}%</span>-->
    <!--      </div>-->
    <!--      <div class="col-4 p-0">-->
    <!--        <span class="small-text">	Start: {{ order.creation_date | kiosk_timezone: 'hh:mm:ss a z': timezone }}</span>-->
    <!--      </div>-->
    <!--      <div class="col-4 p-0">-->
    <!--        <span class="small-text">	Wait Time: {{ order.wait_time ? (order.wait_time / 1000 | time) : 'NaN' }}</span>-->
    <!--      </div>-->
    <!--      <div class="col-4 p-0 pl-1 m-0">-->
    <!--        <span class="small-text">	Complete: {{ order.complete_pct }}%</span>-->
    <!--      </div>-->
    <!--      <div class="col-4 p-0">-->
    <!--				<span-->
    <!--          class="small-text">	{{ order.state == "ACCEPTED" || order.state == "WAITING" || order.state == "PREPARING" ? "Expected Delivery:" : "Delivered:" }}-->
    <!--          {{ order.delivery_time | kiosk_timezone: 'hh:mm:ss a z': timezone }}</span>-->
    <!--      </div>-->
    <!--      <div class="col-4 p-0">-->
    <!--        <span class="small-text"> Processing: {{ getProcessingTime(order) | time }}</span>-->
    <!--      </div>-->
    <!--      <div class="col-4 p-0 pl-1 m-0" *ngIf="order.reordered">-->
    <!--				<span class="small-text"-->
    <!--              matTooltip="Reordered by {{order.reordered_by_user}}"-->
    <!--              [style.color]="'red'">R by {{ order.reordered_by_user }}</span>-->
    <!--      </div>-->
    <!--      <div class="col-4 p-0 pl-1 m-0" *ngIf="order.refund_requested_by">-->
    <!--				<span class="small-text"-->
    <!--              matTooltip="Success: {{order.refunded ? 'Yes': 'No'}}; Amount: {{order.amount_refunded || '?'}}; Date: {{order.refund_request_date | date : 'long'}}"-->
    <!--              [style.color]="'red'">Refunded by {{ order.refund_requested_by }} {{ order.refund_error_message }}</span>-->
    <!--      </div>-->
    <!--      -->
    <!--      <div class="col-11 p-0 pl-1 m-0 " *ngIf="order.requested_delivery_time" style="margin-left: 15px">-->
    <!--        <span class="small-text">  Requested Delivery Time: </span>-->
    <!--        <span class="small-text"-->
    <!--              [style.color]="'white'"-->
    <!--              [style.background-color]="requestedNextDay(order) ? 'red' : 'darkorange'">{{ order.requested_delivery_time | kiosk_timezone: 'MM/dd/yyyy hh:mm:ss a z' : timezone }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <div class="card-footer p-1 pt-2" *ngIf="order">-->
    <!--    <div class="row m-0 p-0">-->
    <!--      <div class="col-10 p-0 m-0">-->
    <!--        <span class="small-text text-truncate"-->
    <!--              style="white-space: nowrap; display: block; font-style: italic; overflow: hidden; text-overflow: ellipsis;">-->
    <!--          &lt;!&ndash;          <ng-container *ngIf="order.jar_name">&ndash;&gt;-->
    <!--          &lt;!&ndash;            {{ order.jar_name ? (order.jar_name + ": " + (order.jar_place ? order.jar_place : "Home")) : "-" }}&ndash;&gt;-->
    <!--          &lt;!&ndash;          </ng-container>&ndash;&gt;-->
    <!--          {{ getLastOrderStep(order) }}-->
    <!--        </span>-->
    <!--      </div>-->
    <!--      <div class="col-2 p-0 m-0 pr-1">-->

    <!--        <ng-container-->
    <!--          *ngIf="order.state==='READY' || (order.state==='CANCELLED' && order.expired && order.discard_date==null)">-->
    <!--          <div class=" dropdown" dropdown [isOpen]="isOpen()" (isOpenChange)="isOpenChange($event)">-->
    <!--            <button-->
    <!--              class=" btn btn-link dropdown-toggle btn-icon dropdown-toggle"-->
    <!--              data-toggle="dropdown"-->
    <!--              dropdownToggle-->
    <!--              type="button"-->
    <!--            >-->
    <!--              <i class=" tim-icons icon-settings-gear-63"> </i>-->
    <!--            </button>-->
    <!--            <div class=" dropdown-menu dropdown-menu-right" *dropdownMenu>-->
    <!--              <a class=" dropdown-item" (click)="pickup(order)"> Pick Up </a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </ng-container>-->
    <!--      </div>-->

    <!--    </div>-->
  </div>
</div>
