<div class="card m-0  " [style.color]="textColor" [style.background-color]="Helper.getColorFromOrderId(getOrderId())">
  <div class="card-content p-1">
    <div class="text-center" style="height: 14px; font-size: 11px; font-weight: bold;">
      {{ device.name.replace(replaceName, '') }}

    </div>

    <div class="text-center">

      <img src="assets/img/jar.png" *ngIf="device.name.startsWith('Jar')" [style]="{width: '30px'}"/>
      <img src="assets/img/blender.png" *ngIf="device.name.startsWith('Blender')" [style]="{width: '30px'}"/>
    </div>

    <div class="text-center">
      <span class="large-text" *ngIf="device.name.startsWith('Jar')"> {{ getCustomerName() }}&nbsp;</span>
    </div>

    <div class="row m-0 p-0">
      <div *ngFor="let ing of device.ingredients" class="col-{{12/device.ingredients.length}} p-1">
        <div class="card {{subCardColor}} m-0 p-1" style="height: 180px;">
          <div class="text-center d-flex justify-content-center align-items-center">
            <div style="position: absolute; bottom: 0; left: 0; height: 100px; width: 100%"
                 [style.background-color]="getColor(getIngredientPercent(ing))"
                 [style.height]="(getIngredientPercent(ing) * 1.8) + 'px'"></div>

            <div
              style="font-size: 11px; font-weight: bold;  ">
              {{ getIngredientName(ing) }} <br/>

              <img *ngIf="getIngredientImage(ing)" width="40" height="40" class="p-2" [src]="getIngredientImage(ing)"
                   [alt]="ing.name"/>
            </div>


          </div>
        </div>
      </div>
    </div>


  </div>
</div>
