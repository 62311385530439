<!--suppress XmlDuplicatedId -->
<ng-container *ngFor="let f of fields; let i = index;">
	<div *ngIf="available(f)" class="row">

		<label class="col-md-{{colWidth}} col-form-label" for="{{name}}_{{f.field}}_{{f.subfield}}">{{f.title}}
			<span *ngIf="f.required" class="text-danger">*</span>

			<a rel="tooltip" title="Pick" class="remove m-1" href="javascript:void(0)"
				 *ngIf="f.isVideo && !canDisable(f)" (click)="uploadVideo()">
				&nbsp;&nbsp;&nbsp;&nbsp;<i class="tim-icons  icon-laptop"></i> Upload
			</a>

			<a rel="tooltip" title="Pick" class="remove m-1" href="javascript:void(0)"
				 *ngIf="f.isVideo && !canDisable(f)" (click)="clearVideo(f.field)">
				&nbsp;&nbsp;&nbsp;&nbsp;<i class="tim-icons  icon-simple-remove"></i> Clear
			</a>

			<a rel="tooltip" title="Open" class="remove m-1" href="javascript:void(0)"
				 *ngIf="f.clickFunction" (click)="f.clickFunction.call(f.clickObject);">
				&nbsp;&nbsp;&nbsp;&nbsp;<i class="tim-icons  icon-link-72"></i> Open
			</a>
		</label>

		<div class="col-md-{{12-colWidth}}">
			<div class=" form-group ">

				<div *ngIf="!isObject(originValue[f.field])">

					<form autocomplete="off">
						<input *ngIf="isInput(f.fieldType)"
									 [(ngModel)]="originValue[f.field]" [disabled]="canDisable(f)"
									 [required]="f.required"
									 [type]="getType(f.fieldType)"
									 autocomplete="off"
									 style="color: black"
									 class="form-control form-control-low"
									 id="{{name}}_{{f.field}}_{{f.subfield}}"/>

						<input type="text" *ngIf="isDate(f.fieldType)" [disabled]="canDisable(f)"
									 (bsValueChange)="saveBsDate($event, f.field)"
									 placeholder="Date" [required]="f.required" [bsValue]="date(f.field)"
									 class="form-control" id="{{name}}_{{f.field}}_{{f.subfield}}"
									 bsDatepicker>

						<input type="text" *ngIf="isDateTime(f.fieldType)" [disabled]="canDisable(f)"
									 (bsValueChange)="saveBsDate($event, f.field)"
									 placeholder="Date" [required]="f.required" [bsValue]="date(f.field)"
									 [bsConfig]="{ withTimepicker: true, dateInputFormat: 'MMMM Do YYYY, h:mm:ss a' }"
									 class="form-control" id="{{name}}_{{f.field}}_{{f.subfield}}"
									 bsDatepicker>

						<timepicker *ngIf="isTime(f.fieldType)" [disabled]="canDisable(f)"
												[hourStep]="1" [minuteStep]="1" [secondsStep]="1"
												[(ngModel)]="time" (ngModelChange)="timeChange(f.field, f.subfield)"
												[showMeridian]="false" [showSeconds]="true"></timepicker>

						<input *ngIf="isDuration(f.fieldType)" [disabled]="canDisable(f)"
									 [required]="f.required" [type]="getType(f.fieldType)"
									 [value]="originValue[f.field] | time"
									 autocomplete="off" class="form-control form-control-low"
									 id="{{name}}_{{f.field}}_{{f.subfield}}"/>

						<div *ngIf="isCheckbox(f.fieldType)" class="form-check">
							<label class=" form-check-label">
								<input class=" form-check-input"
											 [(ngModel)]="originValue[f.field]"
											 [disabled]="canDisable(f)"
											 (change)="onSelectChange(f)"
											 autocomplete="off" id="{{name}}_{{f.field}}_{{f.subfield}}"
											 type="checkbox"/>
								<span class=" form-check-sign"> </span>
							</label>
						</div>

						<textarea *ngIf="isTextArea(f.fieldType)"
											style="color: black"
											[(ngModel)]="originValue[f.field]" [rows]="textAreaRows"
											[required]="f.required" [disabled]="canDisable(f)"
											autocomplete="off" class="form-control form-control-low"
											id="{{name}}_{{f.field}}_{{f.subfield}}">
                    </textarea>

						<img (click)="selectImage(f)" style="cursor: pointer;"
								 *ngIf="isImage(f.fieldType)"
								 [src]="originValue[f.field] ? originValue[f.field] : 'assets/img/no-image.png'"
								 alt="" class="img-thumbnail" height="128px"
								 width="128px"/>

						<input *ngIf="isVideo(f.fieldType)"
									 [(ngModel)]="originValue[f.field]" [disabled]="true"
									 [required]="f.required"
									 type="text"
									 autocomplete="off"
									 class="form-control form-control-low"
									 id="{{name}}_{{f.field}}_{{f.subfield}}"/>

						<input *ngIf="isFile(f.fieldType)"
									 [(ngModel)]="originValue[f.field]" [disabled]="true"
									 [required]="f.required"
									 type="text"
									 autocomplete="off"
									 class="form-control form-control-low"
									 id="{{name}}_{{f.field}}_{{f.subfield}}"/>

						<a rel="tooltip" title="Clear" class="remove m-1" href="javascript:void(0)"
							 *ngIf="(isImage(f.fieldType) || isVideo(f.fieldType) || isFile(f.fieldType)) && !canDisable(f)"
							 (click)="originValue[f.field] = ''">
							<i class="tim-icons icon-simple-remove"></i>
						</a>

						<a rel="tooltip" title="Pick" class="remove m-1" href="javascript:void(0)"
							 *ngIf="(isVideo(f.fieldType) || isFile(f.fieldType)) && !canDisable(f)" (click)="selectImage(f)">
							<i class="tim-icons  icon-laptop"></i>
						</a>

						<select *ngIf="isSelect(f.fieldType)"
										[(ngModel)]="originValue[f.field]"
										[required]="f.required"
										(change)="onSelectChange(f)" [disabled]="canDisable(f)"
										autocomplete="off"
										class="form-control form-control-low selectx" id="{{name}}_{{f.field}}_{{f.subfield}}"
										name="{{name}}_{{f.field}}_{{f.subfield}}"
										size="1">
							<option *ngFor="let option of f.options" value="{{option}}">{{option}}</option>
						</select>

						<ng-select class="p-0 pr"
											 style="height: unset !important;"
											 [maxSelectedItems]="f.maxSelectedItems"
											 *ngIf="isMultiselect(f.fieldType)"
											 [items]="f.multiselectOptions"
											 [(ngModel)]="originValue[f.field]"
											 [disabled]="canDisable(f)"
											 bindLabel="itemName"
											 placeholder="Choose Items"
											 [required]="f.required"
											 [multiple]="f.maxSelectedItems > 1">
						</ng-select>

					</form>
				</div>


				<div *ngIf="isObject(originValue[f.field])">
					<form autocomplete="off">
						<input *ngIf="isInput(f.fieldType)"
									 [(ngModel)]="originValue[f.field][f.subfield]"
									 [disabled]="canDisable(f)"
									 [required]="f.required"
									 [type]="getType(f.fieldType)" autocomplete="off"
									 class="form-control form-control-low"
									 id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"/>

						<input type="text" *ngIf="isDate(f.fieldType)" [disabled]="canDisable(f)"
									 (bsValueChange)="saveBsDate($event, f.field, f.subfield)"
									 placeholder="Date" [required]="f.required" [bsValue]="date(f.field, f.subfield)"
									 class="form-control" id="{{name}}_{{f.field}}_{{f.subfield}}"
									 bsDatepicker>

						<input type="text" *ngIf="isDateTime(f.fieldType)" [disabled]="canDisable(f)"
									 (bsValueChange)="saveBsDate($event, f.field, f.subfield)"
									 placeholder="Date" [required]="f.required" [bsValue]="date(f.field, f.subfield)"
									 class="form-control" id="{{name}}_{{f.field}}_{{f.subfield}}"
									 [bsConfig]="{ dateInputFormat: 'MM/dd/YYYY, h:mm:ss a' }"
									 bsDatepicker>

						<input *ngIf="isDuration(f.fieldType)" [disabled]="canDisable(f)"
									 [required]="f.required"
									 [type]="getType(f.fieldType)"
									 [value]="originValue[f.field][f.subfield] | time"
									 autocomplete="off"
									 class="form-control form-control-low"
									 id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"/>

						<div *ngIf="isCheckbox(f.fieldType)" class="checkbox">
							<input
								[(ngModel)]="originValue[f.field][f.subfield]"
								[disabled]="canDisable(f)" autocomplete="off"
								(change)="onSelectChange(f)"
								id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"
								type="checkbox"/>
							<label for="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"></label>
						</div>

						<textarea *ngIf="isTextArea(f.fieldType)" [rows]="textAreaRows"
											[(ngModel)]="originValue[f.field][f.subfield]"
											[required]="f.required" [disabled]="canDisable(f)"
											autocomplete="off" class="form-control form-control-low"
											id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}">
                    </textarea>

						<img (click)="selectImage(f)" style="cursor: pointer;"
								 *ngIf="isImage(f.fieldType)"
								 [src]="originValue[f.field][f.subfield] ? originValue[f.field][f.subfield] : 'assets/img/no-image.png'"
								 alt=""
								 class="thumb-img-128"/>

						<a rel="tooltip" title="Clear" class="remove m-1" href="javascript:void(0)"
							 *ngIf="isImage(f.fieldType)" (click)="originValue[f.field][f.subfield] = ''">
							<i class="tim-icons icon-simple-remove"></i>
						</a>

						<select *ngIf="isSelect(f.fieldType)"
										[(ngModel)]="originValue[f.field][f.subfield]"
										[required]="f.required"
										[disabled]="canDisable(f)"
										class="form-control form-control-low"
										(change)="onSelectChange(f)"
										id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"
										size="1">
							<option *ngFor="let option of f.options" value="{{option}}">{{option}}</option>
						</select>


						<ng-select class="p-0 pr" style="height: unset !important;"
											 *ngIf="isMultiselect(f.fieldType)"
											 [items]="f.multiselectOptions"
											 [(ngModel)]="originValue[f.field]"
											 [disabled]="canDisable(f)"
											 bindLabel="itemName"
											 placeholder="Choose Items"
											 [required]="f.required" [multiple]="f.maxSelectedItems > 1">
						</ng-select>
					</form>
				</div>

			</div>
		</div>
	</div>
</ng-container>

