import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { Observable } from 'rxjs';
import {
    KioskBean,
    KioskContentBean,
    KioskTokenBean,
    ResponseBean,
    ResponseItemBean,
    TabletConfigurationBean,
    TabletRegisterRequestBean
} from '../model/model';

@Injectable()
export class StoreManagerService extends BaseRequestService {

    getKioskContent(kioskId: number): Observable<ResponseItemBean<KioskContentBean>> {
        return this.get('kiosk/' + kioskId + '/content');
    }

    getKiosk(id: number): Observable<ResponseItemBean<KioskBean>> {
        return this.getWithKioskToken(id, 'store_manager/kiosk/' + id, 1, null, false);
    }

    cancelOrder(kioskId: number, orderId: number, reason: string, sendSMS: boolean = true): Observable<ResponseBean> {
        return this.getWithKioskToken(kioskId, `store_manager/order/${orderId}/cancel?send_sms=${sendSMS}&reason=${reason}`, 1);
    }

    reorderOrder(kioskId: number, orderId: number, reason: string): Observable<ResponseBean> {
        return this.getWithKioskToken(kioskId, `store_manager/order/${orderId}/reorder?reason=${reason}`, 1);
    }

    refundOrder(kioskId: number, orderId: number, reason: string, pinCode: string): Observable<ResponseBean> {
        return this.getWithKioskToken(kioskId, `store_manager/order/${orderId}/refund/${pinCode}/?reason=${reason}`, 1);
    }

    registerTablet(body: TabletRegisterRequestBean): Observable<ResponseItemBean<TabletConfigurationBean>> {
        return this.post('tablet/register', body)
    }

    registerUser(): Observable<ResponseItemBean<KioskTokenBean[]>> {
        return this.get('kiosk/tokens')
    }

    sendAuthCode(body: { email?: string,  phoneNumber?: string}): Observable<ResponseItemBean<TabletConfigurationBean>> {
        return this.post('tablet/send_auth_code', body)
    }

    // tslint:disable-next-line:variable-name
    login(kiosk_id: number, user_name: string): Observable<ResponseBean> {
        return this.getWithKioskToken(kiosk_id, `store-manager/login`, 2, { kiosk_id, user_name });
    }

    // tslint:disable-next-line:variable-name
    logout(kiosk_id: number, user_name: string): Observable<ResponseItemBean<{ url: string }>> {
        return this.getWithKioskToken(kiosk_id, `store-manager/logout`, 2, { kiosk_id, user_name });
    }
}
